@import './fonts/fonts.css';

:root {
    --privmx-contact-monospace-font: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

* {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: Aneba, arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: var(--privmx-contact-monospace-font);
}
