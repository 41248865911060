@font-face {
    font-family: 'Aneba';
    src: url('./Aneba_TTF/privmx-Mediumitalic.ttf');
    font-weight: 700;
    font-style: italic;
}

@font-face {
    font-family: 'Aneba';
    src: url('./Aneba_TTF/privmx-Medium.ttf');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Aneba';
    src: url('./Aneba_TTF/privmx-Italic.ttf');
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: 'Aneba';
    src: url('./Aneba_TTF/privmx-Mediumitalic.ttf');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Aneba';
    src: url('./Aneba_TTF/privmx-Medium.ttf');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Aneba';
    src: url('./Aneba_TTF/privmx-Regular.ttf');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Aneba';
    src: url('./Aneba_TTF/privmx-Medium.ttf');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Aneba';
    src: url('./Aneba_TTF/privmx-Mediumitalic.ttf');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Aneba';
    src: url('./Aneba_TTF/privmx-UltraLightitalic.ttf');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Aneba';
    src: url('./Aneba_TTF/privmx-UltraLight.ttf');
    font-weight: 200;
    font-style: normal;
}
